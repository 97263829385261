<template>
  <div class="member_info_container">
    <MemberTitle :memberTitle="L['会员信息']"></MemberTitle>
<!--    <ul class="title">-->
<!--      <li id="basicMes" @click="tabChange(0)" class="basic_title">基本信息</li>-->
<!--      <li id="relevancyMes" @click="tabChange(1)">用药人信息</li>-->
<!--    </ul>-->
    <div class="title">
      <div id="basicMes" :class="isBasic ? 'sel_title' : ''" class="basic_title" @click="tabChange(0)">
        基本信息
      </div>
      <div id="relevancyMes" :class="isRelevancy ? 'sel_title' : ''" @click="tabChange(1)">
        用药人信息
      </div>
      <div v-if="isRelevancy" class="add_btn">
        <button class="add_people" @click="addPeople">+ 新增用药人</button>
      </div>
    </div>
    <div class="member_info">
      <!--基本信息-->
      <div v-if="isBasic">
        <table class="form_item">
          <tr>
            <td>
              <span class="tag_name">用户头像：</span>

            </td>
            <td>
              <form action="" enctype="multipart/form-data">

                <label for="file" class="chooseBtn"><img :src="tempSrc" id="show"></label>
                <input id="file" class="filepath" @change="changepic" type="file" name="image" size="1"
                       accept="image/gif,image/jpeg,image/gif">

              </form>

            </td>
          </tr>
          <tr>
            <td></td>
            <td>{{ L['仅支持JPG、GIF、PNG、JPEG、BMP格式;文件大小请在4.0MB之内。'] }}</td>
          </tr>
          <tr>
            <td><span class="tag_name">{{ L['会员名：'] }}</span></td>
            <td>
              <el-input id="memberName" v-model="memberNameInput" :disabled="true"></el-input>
            </td>
          </tr>
          <tr>
            <td><span class="tag_name">{{ L['真实姓名'] }}：</span></td>
            <td>
              <el-input id="memberTrueName" v-model="memberTrueName" maxlength="10" show-word-limit>
              </el-input>
            </td>
          </tr>
          <tr>
            <td><span class="tag_name">{{ L['性别：'] }}</span></td>
            <td id="sex">
              <el-radio-group v-model="sex" fill="red">
                <el-radio :label="0">{{ L['保密'] }}</el-radio>
                <el-radio :label="1">{{ L['男'] }}</el-radio>
                <el-radio :label="2">{{ L['女'] }}</el-radio>
              </el-radio-group>
            </td>

          </tr>
          <tr>
            <td><span class="tag_name">{{ L['昵称：'] }}</span></td>
            <td>
              <el-input type="text" id="memberNickName" v-model="memberNickNameInput" maxlength="15"
                        show-word-limit></el-input>
            </td>
          </tr>
          <tr>
            <td><span class="tag_name">{{ L['生日：'] }}</span></td>
            <td id="birth">
              <select name="year" id="year" v-model="year">
                <option v-for="(item,index) in yearArr" :key="index">{{ item }}</option>
              </select><label for="">{{ L['年'] }}</label>
              <select name="month" id="month" v-model="month">
                <option v-for="(item,index) in monthArr" :key="index">{{ item }}</option>
              </select><label for="">{{ L['月'] }}</label>
              <select name="day" id="day" v-model="day">
                <option v-for="(item,index) in dayArr" :key="index">{{ item }}</option>
              </select><label for="">{{ L['日'] }}</label>
            </td>
          </tr>
        </table>
        <div class="memInfo_save">
          <el-button @click="memberInfoSave">{{ L['保存'] }}</el-button>
        </div>
      </div>
      <!--用药人信息-->
      <div v-if="isRelevancy" class="relevancy_mes">
        <div class="rel_people_box">
          <div v-if="isHavePeople">
            <div class="rel_people" v-for="(peopleItem,index) in peopleList.data" :key="index">
              <div class="rel_people_top">
                <div class="avatar_box">
                  <img src="../../assets/wechat_login.png" alt="用户头像" style="width: 72px;height: 72px">
                </div>
                <div class="people_box">
                  <div class="people_mes">
                    <div class="title_box">
                      <div class="people_name">
                        {{ peopleItem.name }}
                      </div>
                      <div class="relation">
                        {{ peopleItem.relation }}
                      </div>
                    </div>
                    <div class="number_box">
                      <span class="number">电话：</span>
                      <span class="number">{{ peopleItem.phone }}</span>
                    </div>
                  </div>
                </div>
                <div class="idCard">
                  <span class="number">身份证号：</span>
                  <span class="number" v-if="peopleItem.idNumber">{{ peopleItem.idNumber.slice(0,4)+ '**********' + peopleItem.idNumber.slice(14,18) }}</span>
                </div>
              </div>
              <div class="is_health" @click="viewState(peopleItem)">

                <text v-if="peopleItem.medicalHistory[0][0] != '无'">有病史、</text>
                <text v-if="peopleItem.medicalHistory[0][0] == '无'">无病史、</text>

                <text v-if="peopleItem.drugAllergy[0][0] != '无'">有药物过敏史、</text>
                <text v-if="peopleItem.drugAllergy[0][0] == '无'">无药物过敏史、</text>

                <text v-if="peopleItem.foodTouchAllergy[0][0] != '无'">有食物/接触过敏史、</text>
                <text v-if="peopleItem.foodTouchAllergy[0][0] == '无'">无食物/接触过敏史、</text>

                <text v-if="peopleItem.geneticDisorders[0][0] != '无'">有家族病史、</text>
                <text v-if="peopleItem.geneticDisorders[0][0] == '无'">无家族病史、</text>

                <text v-if="peopleItem.isSmoking[0][0] != '无'">有吸烟、</text>
                <text v-if="peopleItem.isSmoking[0][0] == '无'">无吸烟、</text>

                <text v-if="peopleItem.isWine[0][0] != '无'">有饮酒、</text>
                <text v-if="peopleItem.isWine[0][0] == '无'">无饮酒、</text>

                <span v-if="peopleItem.isPregnancy[0] != 'none' ">
                 <text v-if="peopleItem.isPregnancy[0][0] != '无' ">有妊娠哺乳、</text>
                 <text v-if="peopleItem.isPregnancy[0][0] == '无' " >无妊娠哺乳、</text>
                </span>

              </div>
              <!--            <span class="view_more" @click="viewMore(index)">查看用药人详情</span>-->
            </div>
          </div>
          <div v-if="!isHavePeople" class="noPeople">
            您暂未添加用药人
          </div>
        </div>
      </div>
      <!--查看用药人详情-->
      <div v-if="details" class="people_detial">
        <!--用药人基本信息-->
        <div>
          <div class="detail_title" @click="peopleBack">&nbsp;&nbsp;&nbsp;&lt;&nbsp;&nbsp;用药人详情</div>
          <div class="personal_detial">
            <div style="margin-top: 5px">
              <img src="../../assets/wechat_login.png" alt="用户头像" style="width: 72px;height: 72px">
            </div>
            <div class="personal_mes" v-for="(duiItem,index) in duiList.data" :key="index">
              <div class="personal_public">
                <div>
                  <span>姓名：</span>
                  <span>{{ duiItem.name }}</span>
                </div>
                <div style="width: 233px">
                  <span>电话：</span>
                  <span>{{ duiItem.phone }}</span>
                </div>
              </div>
              <div class="personal_public">
                <div>
                  <span>关系：</span>
                  <span>{{ duiItem.relation }}</span>
                </div>
                <div>
                  <span>身份证号：</span>
                  <span>{{ duiItem.idNumber }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--订单信息-->
        <!--        <div class="order_table">-->
        <!--          &lt;!&ndash;订单信息列表顶部标题&ndash;&gt;-->
        <!--          <div class="table_title">-->
        <!--            <div class="table_title_pre">-->
        <!--              <span>订单详情</span>-->
        <!--            </div>-->
        <!--            <div class="table_title_pre">-->
        <!--              <span>单价</span>-->
        <!--            </div>-->
        <!--            <div class="table_title_pre">-->
        <!--              <span>数量</span>-->
        <!--            </div>-->
        <!--            <div class="table_title_pre">-->
        <!--              <span>金额</span>-->
        <!--            </div>-->
        <!--            <div class="table_title_pre">-->
        <!--              <span>诊断</span>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          &lt;!&ndash;订单信息列表&ndash;&gt;-->
        <!--          <div class="cart_lists">-->
        <!--            <div class="cart_lists_pre">-->
        <!--              &lt;!&ndash;订单信息&ndash;&gt;-->
        <!--              <div class="orde_mes ">-->
        <!--                <span>JY手表专营店</span>-->
        <!--                <span>订单号：19802039821873213</span>-->
        <!--                <span>2019-09-01 14:28:30</span>-->
        <!--              </div>-->
        <!--              &lt;!&ndash;商品信息&ndash;&gt;-->
        <!--              <div class="goods_con">-->
        <!--                &lt;!&ndash;商品列表&ndash;&gt;-->
        <!--                <div class="goods_list">-->
        <!--                  <div class="goods_list_left">-->
        <!--                    <div class="goods_pic">-->
        <!--                      <div style="margin-top: 5px">-->
        <!--                        <img src="../../assets/wechat_login.png" alt="用户头像" style="width: 62px;height: 62px">-->
        <!--                      </div>-->
        <!--                      <div class="numbers">-->
        <!--                        <div>缬沙坦胶囊</div>-->
        <!--                        <div style="color: #AAAAAA">80mg*28粒/盒</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                    <div class="goods_number">-->
        <!--                      99.00-->
        <!--                    </div>-->
        <!--                    <div class="goods_number">-->
        <!--                      1-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="goods_list_middle">¥99.00</div>-->
        <!--                  <div class="goods_list_right">*****</div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          &lt;!&ndash;订单信息列表&ndash;&gt;-->
        <!--          <div class="cart_lists">-->
        <!--            <div class="cart_lists_pre">-->
        <!--              &lt;!&ndash;订单信息&ndash;&gt;-->
        <!--              <div class="orde_mes ">-->
        <!--                <span>JY手表专营店</span>-->
        <!--                <span>订单号：19802039821873213</span>-->
        <!--                <span>2019-09-01 14:28:30</span>-->
        <!--              </div>-->
        <!--              &lt;!&ndash;商品信息&ndash;&gt;-->
        <!--              <div class="goods_con">-->
        <!--                &lt;!&ndash;商品列表&ndash;&gt;-->
        <!--                <div class="goods_list">-->
        <!--                  <div>-->
        <!--                    <div class="goods_list_left">-->
        <!--                      <div class="goods_pic">-->
        <!--                        <div style="margin-top: 5px">-->
        <!--                          <img src="../../assets/wechat_login.png" alt="用户头像" style="width: 62px;height: 62px">-->
        <!--                        </div>-->
        <!--                        <div class="numbers">-->
        <!--                          <div>缬沙坦胶囊</div>-->
        <!--                          <div style="color: #AAAAAA">80mg*28粒/盒</div>-->
        <!--                        </div>-->
        <!--                      </div>-->
        <!--                      <div class="goods_number">-->
        <!--                        99.00-->
        <!--                      </div>-->
        <!--                      <div class="goods_number">-->
        <!--                        1-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                    <div class="goods_list_left">-->
        <!--                      <div class="goods_pic">-->
        <!--                        <div style="margin-top: 5px">-->
        <!--                          <img src="../../assets/wechat_login.png" alt="用户头像" style="width: 62px;height: 62px">-->
        <!--                        </div>-->
        <!--                        <div class="numbers">-->
        <!--                          <div>缬沙坦胶囊</div>-->
        <!--                          <div style="color: #AAAAAA">80mg*28粒/盒</div>-->
        <!--                        </div>-->
        <!--                      </div>-->
        <!--                      <div class="goods_number">-->
        <!--                        99.00-->
        <!--                      </div>-->
        <!--                      <div class="goods_number">-->
        <!--                        1-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="goods_list_middle">¥99.00</div>-->
        <!--                  <div class="goods_list_right">*****</div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
  <!--添加用药人弹窗-->
  <el-dialog title="用药人" v-model="addForm" width="550px" :close-on-click-modal="false" @close="close_dialog">
    <el-form :model="form.data" :rules="rules" ref="addForms">
      <el-form-item label="姓名：" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.data.name" autocomplete="off" clearable></el-input>
      </el-form-item>
      <el-form-item label="身份证号：" :label-width="formLabelWidth" prop="id">
        <el-input v-model="form.data.id" autocomplete="off"  @input="getId(form.data.id)" clearable></el-input>
<!--        <div class="small">小于六岁</div>-->
      </el-form-item>
      <el-form-item label="手机号：" :label-width="formLabelWidth" prop="phone">
        <el-input v-model="form.data.phone" autocomplete="off" clearable></el-input>
      </el-form-item>
      <!--      <el-form-item label="短信验证码：" :label-width="formLabelWidth" prop="phoneCode">-->
      <!--        <el-input v-model="form.data.phoneCode" autocomplete="off"></el-input>-->
      <!--      </el-form-item>-->
      <el-form-item label="短信验证码：" :label-width="codelWidth" prop="phoneCode">
        <div class="bind_code">
          <el-input
              v-model="form.data.phoneCode"
              autocomplete="off"
              placeholder="请输入验证码"
          />
          <el-button
              class="bind_code_gain"
              :disabled="disabled">
            验证码
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="关系：" :label-width="formLabelWidth" prop="region">
        <el-select v-model="form.data.region" @change="selRegion(form.data.region)" placeholder="请选择与用药人关系" clearable>
          <el-option label="本人" value="本人"></el-option>
          <el-option label="父母" value="父母"></el-option>
          <el-option label="子女" value="子女"></el-option>
          <el-option label="配偶" value="配偶"></el-option>
          <el-option label="朋友" value="朋友"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="健康信息：" :label-width="formLabelWidth" >
        <el-input v-model="form.data.isHealth" @click="healthState" :placeholder="placeholder()">
        </el-input>
      </el-form-item>

      <el-form-item label=" " :label-width="formLabelWidth" prop="isRead" >
        <el-checkbox v-if="addForm" label="我已阅读并同意" v-model="form.data.isRead" @change="checkbox" :checked="checkboxv"></el-checkbox>
        <a class="colors" :href="`/addAgreement`" target="_blank">《用药人授权协议》</a>
        <text v-if="ifcheckboxv" style="display: block;color: #f56c6c;line-height: 10px">请阅读并勾选协议</text>
      </el-form-item>

    </el-form>
    <template #footer>
        <span class="dialog-footer">
          <el-button class="btn_over" @click="close_dialog">取 消</el-button>
          <el-button class="btn_over el-btn" :disabled="disables" @click="addConfirm">确 定</el-button>
        </span>
    </template>
  </el-dialog>
  <!--用户协议-->
  <el-dialog title="用户协议" :close-on-click-modal="false" v-model="agreementForm" width="350px" >
    这是协议页面
  </el-dialog>
  <!--查看健康信息弹窗-->
  <el-dialog v-model="viewHealthState" :close-on-click-modal="false" title="健康信息">
    <div v-for="(item,index) in illList.data" :key="index">
      <div class="illness_box">
        <div class="illness_title">
          {{ item.name }}:
        </div>
        <div class="illness" :key="indexs">
          <button class="illness_btn" v-for="(items,indexs) in item.details">{{ items }}</button>
        </div>
      </div>
    </div>
  </el-dialog>
  <!-- 添加健康状态弹窗 -->
  <el-dialog v-model="healthStateDialog" :close-on-click-modal="false" title="健康信息" @close="closeHealth">
    <div class="illness_box">
      <div class="illness_title">
        过往病史:
      </div>
      <div class="illness">
        <button class="illness_btn" :class="handleBtnClass(0,0)" @click="handleIllnessClick(0,0)">无</button>
        <button class="illness_btn" :class="handleBtnClass(0,1)" @click="handleIllnessClick(0,1)">糖尿病</button>
        <button class="illness_btn" :class="handleBtnClass(0,2)" @click="handleIllnessClick(0,2)">高血压</button>
        <button class="illness_btn" :class="handleBtnClass(0,3)" @click="handleIllnessClick(0,3)">心脑血管疾病</button>
        <button class="illness_btn" :class="handleBtnClass(0,4)" @click="handleIllnessClick(0,4)">高血脂</button>
        <button class="illness_btn" :class="handleBtnClass(0,5)" @click="handleIllnessClick(0,5)">肝炎</button>
        <button class="illness_btn" :class="handleBtnClass(0,6)" @click="handleIllnessClick(0,6)">高尿酸血症</button>
        <button class="illness_btn" :class="handleBtnClass(0,7)" @click="handleIllnessClick(0,7)">支气管哮喘</button>
        <button class="illness_btn" :class="handleBtnClass(0,8)" @click="handleIllnessClick(0,8)">结核病</button>
        <button class="illness_btn" :class="handleBtnClass(0,9)" @click="handleIllnessClick(0,9)">恶性肿瘤</button>
        <button class="illness_btn" :class="handleBtnClass(0,10)" @click="handleIllnessClick(0,10)">肝病</button>
        <button class="illness_btn" :class="handleBtnClass(0,11)" @click="handleIllnessClick(0,11)">肾病</button>
      </div>
    </div>
    <div class="illness_box">
      <div class="illness_title">
        药物过敏史:
      </div>
      <div class="illness">
        <button class="illness_btn" :class="handleBtnClass(1,0)" @click="handleIllnessClick(1,0)">无</button>
        <button class="illness_btn" :class="handleBtnClass(1,1)" @click="handleIllnessClick(1,1)">青霉素</button>
        <button class="illness_btn" :class="handleBtnClass(1,2)" @click="handleIllnessClick(1,2)">头孢类抗生素</button>
        <button class="illness_btn" :class="handleBtnClass(1,3)" @click="handleIllnessClick(1,3)">破伤风抗毒素(TAT)</button>
        <button class="illness_btn" :class="handleBtnClass(1,4)" @click="handleIllnessClick(1,4)">普鲁卡因</button>
        <button class="illness_btn" :class="handleBtnClass(1,5)" @click="handleIllnessClick(1,5)">地卡因</button>
        <button class="illness_btn" :class="handleBtnClass(1,6)" @click="handleIllnessClick(1,6)">磺胺类药物</button>
        <button class="illness_btn" :class="handleBtnClass(1,7)" @click="handleIllnessClick(1,7)">维生素B1</button>
        <button class="illness_btn" :class="handleBtnClass(1,8)" @click="handleIllnessClick(1,8)">泛影葡胺</button>
        <button class="illness_btn" :class="handleBtnClass(1,9)" @click="handleIllnessClick(1,9)">阿司匹林</button>
        <button class="illness_btn" :class="handleBtnClass(1,10)" @click="handleIllnessClick(1,10)">链霉素</button>
        <button class="illness_btn" :class="handleBtnClass(1,11)" @click="handleIllnessClick(1,11)">安定</button>
        <button class="illness_btn" :class="handleBtnClass(1,12)" @click="handleIllnessClick(1,12)">去痛片</button>
      </div>
    </div>
    <div class="illness_box">
      <div class="illness_title">
        食物/接触过敏史:
      </div>
      <div class="illness">
        <button class="illness_btn" :class="handleBtnClass(2,0)" @click="handleIllnessClick(2,0)">无</button>
        <button class="illness_btn" :class="handleBtnClass(2,1)" @click="handleIllnessClick(2,1)">鸡蛋</button>
        <button class="illness_btn" :class="handleBtnClass(2,2)" @click="handleIllnessClick(2,2)">面粉</button>
        <button class="illness_btn" :class="handleBtnClass(2,3)" @click="handleIllnessClick(2,3)">坚果</button>
        <button class="illness_btn" :class="handleBtnClass(2,4)" @click="handleIllnessClick(2,4)">海鲜</button>
        <button class="illness_btn" :class="handleBtnClass(2,5)" @click="handleIllnessClick(2,5)">豆制品</button>
        <button class="illness_btn" :class="handleBtnClass(2,6)" @click="handleIllnessClick(2,6)">花粉</button>
        <button class="illness_btn" :class="handleBtnClass(2,7)" @click="handleIllnessClick(2,7)">动物毛发</button>
        <button class="illness_btn" :class="handleBtnClass(2,8)" @click="handleIllnessClick(2,8)">酒精</button>
        <button class="illness_btn" :class="handleBtnClass(2,9)" @click="handleIllnessClick(2,9)">牛奶</button>
        <button class="illness_btn" :class="handleBtnClass(2,10)" @click="handleIllnessClick(2,10)">粉尘</button>
        <button class="illness_btn" :class="handleBtnClass(2,11)" @click="handleIllnessClick(2,11)">螨虫</button>
      </div>
    </div>
    <div class="illness_box">
      <div class="illness_title">
        家族遗传病史:
      </div>
      <div class="illness">
        <button class="illness_btn" :class="handleBtnClass(3,0)" @click="handleIllnessClick(3,0)">无</button>
        <button class="illness_btn" :class="handleBtnClass(3,1)" @click="handleIllnessClick(3,1)">高血压</button>
        <button class="illness_btn" :class="handleBtnClass(3,2)" @click="handleIllnessClick(3,2)">糖尿病</button>
        <button class="illness_btn" :class="handleBtnClass(3,3)" @click="handleIllnessClick(3,3)">脑梗</button>
        <button class="illness_btn" :class="handleBtnClass(3,4)" @click="handleIllnessClick(3,4)">脑出血</button>
        <button class="illness_btn" :class="handleBtnClass(3,5)" @click="handleIllnessClick(3,5)">癌症</button>
        <button class="illness_btn" :class="handleBtnClass(3,6)" @click="handleIllnessClick(3,6)">过敏性疾病</button>
        <button class="illness_btn" :class="handleBtnClass(3,7)" @click="handleIllnessClick(3,7)">哮喘</button>
        <button class="illness_btn" :class="handleBtnClass(3,8)" @click="handleIllnessClick(3,8)">癫痫</button>
        <button class="illness_btn" :class="handleBtnClass(3,9)" @click="handleIllnessClick(3,9)">白癜风</button>
        <button class="illness_btn" :class="handleBtnClass(3,10)" @click="handleIllnessClick(3,10)">近视</button>
        <button class="illness_btn" :class="handleBtnClass(3,11)" @click="handleIllnessClick(3,11)">精神疾病</button>
        <button class="illness_btn" :class="handleBtnClass(3,12)" @click="handleIllnessClick(3,12)">超重</button>
        <button class="illness_btn" :class="handleBtnClass(3,13)" @click="handleIllnessClick(3,13)">骨质疏松</button>
        <button class="illness_btn" :class="handleBtnClass(3,14)" @click="handleIllnessClick(3,14)">冠心病</button>
      </div>
    </div>
    <div class="illness_box">
      <div class="illness_title">
        吸烟:
      </div>
      <div class="illness">
        <button class="illness_btn" :class="handleBtnClass(4,0)" @click="handleIllnessClick(4,0)">无</button>
        <button class="illness_btn" :class="handleBtnClass(4,1)" @click="handleIllnessClick(4,1)">有</button>
      </div>
    </div>
    <div class="illness_box">
      <div class="illness_title">
        饮酒:
      </div>
      <div class="illness">
        <button class="illness_btn" :class="handleBtnClass(5,0)" @click="handleIllnessClick(5,0)">无</button>
        <button class="illness_btn" :class="handleBtnClass(5,1)" @click="handleIllnessClick(5,1)">有</button>
      </div>
    </div>
    <div v-if="uerSex" class="illness_box">
      <div class="illness_title">
        妊娠哺乳:
      </div>
      <div class="illness">
        <button class="illness_btn" :class="handleBtnClass(6,0)" @click="handleIllnessClick(6,0)">无</button>
        <button class="illness_btn" :class="handleBtnClass(6,1)" @click="handleIllnessClick(6,1)">备孕中</button>
        <button class="illness_btn" :class="handleBtnClass(6,2)" @click="handleIllnessClick(6,2)">怀孕中</button>
        <button class="illness_btn" :class="handleBtnClass(6,3)" @click="handleIllnessClick(6,3)">正在哺乳</button>
      </div>
    </div>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="illConfirm">确 定</el-button>
        </span>
    </template>
  </el-dialog>
</template>
<script>
import {ElInput, ElButton, ElMessage} from "element-plus";
import {getCurrentInstance, ref, onMounted, watch, reactive,toRefs} from 'vue';
import MemberTitle from '../../components/MemberTitle'
import {apiUrl} from "../../utils/config";
import IdentityCodeValid from '../../utils/idCardValidate'

import axios from 'axios'
import {checkPwd} from "../../utils/common";

export default {
  name: 'MemberInfo',
  components: {
    ElInput,
    ElButton,
    MemberTitle
  },
  setup() {
    const isHavePeople = ref(true)
    const disables = ref(false)
    const illnessList =  reactive({data: [ [0],[0],[0],[0],[0],[0],[0] ] });
    const healthStateDialog = ref(false)
    //查看健康信息
    const viewHealthState = ref(false)
    // 对应用药人健康状况list
    const illList = reactive({data: {
        medicalHistory: {name:'过往病史',details: {}},
        drugAllergy: {name:'药物过敏史',details: {}},
        foodTouchAllergy: {name:'食物/接触过敏史',details: {}},
        geneticDisorders: {name:'家族遗传病',details: {}},
        isSmoking: {name:'吸烟',details: {}},
        isWine: {name:'饮酒',details: {}},
        isPregnancy: {name:'妊娠哺乳',details: {}},
      }})
    //对应用药人id
    const peopleId = ref()
    //用户id
    const memberId = ref()
    //用户名
    const memberName = ref()
    const isBasic = ref(true) //tab切换
    const isRelation = ref(false) //关系判断
    const isId = ref(false) //身份证号是否重复判断
    const uerSex = ref(true) //性别判断
    const isRelevancy = ref(false) //tab切换
    // 协议
    const agreementForm = ref(false)
    // 用药人
    const addForm = ref(false)
    // 协议
    const checkboxv = ref(false)
    const ifcheckboxv = ref(false)
    // 用药人详情
    const details = ref(false)
    // 用药人form
    const form = reactive({data: {}});
    // 用药人list
    const peopleList = reactive({data: []});
    // 对应用药人list
    const duiList = reactive({data: []});
    const formLabelWidth = "110px";
    const codelWidth = "110px";
    const agreeFlag = ref(false);//同意注册协议标识，默认不同意
    const errorMsg = ref();//错误提示
    const {proxy} = getCurrentInstance()
    const L = proxy.$getCurLanguage()
    const memberNameInput = ref('')//会员名
    const memberNickNameInput = ref('')//会员名称
    const memberTrueName = ref('')//会员姓名
    const avatar = ref('')//用户头像
    const tempSrc = ref('')
    const sex = ref(0)//性别
    const yearArr = ref(Array.from({length: 80}, (v, k) => k).map(item => new Date().getFullYear() - item))//生成年份数组-80年间隔
    const year = ref('')//当前年份
    const monthArr = ref(ref(Array.from({length: 12}, (v, k) => k).map(item => 12 - item)))//生成当月的月数
    const month = ref('')//当前月分
    let d = new Date(year.value, month.value, 0).getDate();//生成设置月分的当月天数
    const dayArr = ref(Array.from({length: d}, (v, k) => k).map(item => item + 1))//生成当月天数数组
    const day = ref('')//当前天、
    const ids = ref(false)

    const placeholder = () => {
      return
    }
    // 判断新增关系时,本人是否存在
    const selRegion = (region) => {
      if(region === '本人') {
        var param = {
          relation: region,
          isAdd: 0
        };
        var url = "v3/member/front/memberMedicineUser/add";
        proxy.$post(url, param).then(res => {
          if (res.state == 200) {
            disables.value = true
            isRelation.value = true
          }else {
            disables.value = false
            isRelation.value = false
          }
        })
      }else {
        disables.value = false
        isRelation.value = false
      }

    }
    // 判断新增关系时,身份证号是否重复
    const getId = (id) => {
      let data = id.toUpperCase() //转换成大写
      ids.value = IdentityCodeValid( data )//得到 false
      if (ids.value) {
        disables.value = false
        // 是否小于六岁验证
        // let org_birthday = id.substring(6, 14);
        let org_gender = id.substring(16, 17);
        // false-男
        uerSex.value = org_gender % 2 == 1 ? false : true;
        // let birthday = org_birthday.substring(0, 4) + "-" + org_birthday.substring(4, 6) + "-" + org_birthday.substring(6, 8);
        // var birthdays = new Date(birthday.replace(/-/g, "/"));
        // let d = new Date();
        // let age = d.getFullYear() - birthdays.getFullYear() - (d.getMonth() < birthdays.getMonth() ||
        //     (d.getMonth() == birthdays.getMonth() && d.getDate() < birthdays.getDate()) ? 1 : 0);
        //
        // if (age < 6) {
        //   ElMessage.error('用药人小于六岁不可添加');
        //   disables.value = true
        // }
        var param = {
          idNumber: id,
          isAdd: 0
        };
        var url = "v3/member/front/memberMedicineUser/add";
        proxy.$post(url, param).then(res => {
          if (res.state == 200) {
            disables.value = true
            isId.value = true
            console.log('disables.value true',disables.value)
            // ElMessage.error(res.msg);
          }else {
            disables.value = false
            isId.value = false
            console.log('false',disables.value)
          }
        })
      }
    }

    const healthState = () => {
      //新增健康状况弹窗
      healthStateDialog.value = true
    }
    const viewState = (peopleItem) => {
      illList.data.medicalHistory.details = peopleItem.medicalHistory
      illList.data.drugAllergy.details = peopleItem.drugAllergy
      illList.data.foodTouchAllergy.details = peopleItem.foodTouchAllergy
      illList.data.geneticDisorders.details = peopleItem.geneticDisorders
      illList.data.isPregnancy.details = peopleItem.isPregnancy
      illList.data.isSmoking.details = peopleItem.isSmoking
      illList.data.isWine.details = peopleItem.isWine
      // peopleId.value = peopleList.data[index].id
      viewHealthState.value = true
    }
    const illConfirm = () => {
      illnessList.data = illnessList.data
      console.log('illnessList.data',illnessList.data)
      let value = ''
      if(illnessList.data[0][0] == 0){
        value += ''
      }else {
        value += '有病史、'
      }
      if(illnessList.data[1][0] == 0){
        value += ''
      }else {
        value += '有药物过敏、'
      }
      if(illnessList.data[2][0] == 0){
        value += ''
      }else {
        value += '有食物/接触过敏史、'
      }
      if(illnessList.data[3][0] == 0){
        value += ''
      }else {
        value += '有家族病史、'
      }
      if(illnessList.data[4][0] == 0){
        value += ''
      }else {
        value += '有吸烟、'
      }
      if(illnessList.data[5][0] == 0){
        value += ''
      }else {
        value += '有饮酒、'
      }
      if(illnessList.data[6][0] == 0){
        value += ''
      }else {
        value += '有妊娠哺乳、'
      }
      if(illnessList.data[0][0] == 0 && illnessList.data[1][0] == 0&& illnessList.data[2][0] == 0&& illnessList.data[3][0] == 0&& illnessList.data[4][0] == 0 && illnessList.data[5][0] == 0 && illnessList.data[6][0] == 0){
      	value = '无病史、无药物过敏、无食物/接触过敏史、无家族病史、无吸烟、无饮酒、无妊娠哺乳'
      }
      form.data.isHealth = value
      healthStateDialog.value = false
    }

    const handleBtnClass = (illnessIndex,value) => {
      return illnessList.data[illnessIndex].findIndex((_) => _ === value) > -1 ? 'illness_btn_selected' : 'illness_btn'
    }

    const handleIllnessClick = (illnessIndex,value) => {
      const index = illnessList.data[illnessIndex].findIndex((_) => _ === value)
      const first =illnessList.data[illnessIndex].findIndex((_) => _ === 0)
      if (value === 0) {
        //选择 "无" 时,清空列表
        if (first > -1) {
          const arr = illnessList.data[illnessIndex].filter(item => item === 0);
          illnessList.data[illnessIndex] = arr
        }else {
          const arr = illnessList.data[illnessIndex].filter(item => item === 0);
          console.log('arr',arr)
          illnessList.data[illnessIndex] = arr
          illnessList.data[illnessIndex].push(value)
        }
      } else {
       if( illnessIndex === 6) {
         // 如果已经选中，则取消选中
         if (index > -1){
           illnessList.data[illnessIndex].splice(index, 1)
           illnessList.data[illnessIndex].push(0)
         } else {
           // 如果未选中，则选中
           if ( first > -1) {
             illnessList.data[illnessIndex].splice(first,1)
             illnessList.data[illnessIndex].push(value)
           }else {
             let ios = illnessList.data[illnessIndex].filter(item => item === 0)
             illnessList.data[illnessIndex] = ios
             //把其他的删掉,把新选中的添加进去
             illnessList.data[illnessIndex].push(value)
           }
         }
       } else {
         // 如果已经选中，则取消选中
         if (index > -1){
           illnessList.data[illnessIndex].splice(index, 1)
           if (illnessList.data[illnessIndex].length === 0) {
             illnessList.data[illnessIndex].push(0)
           }
           console.log('已经选中',illnessList.data[illnessIndex])
         } else {
           // 如果未选中，则选中
           if ( first > -1) {
             illnessList.data[illnessIndex].splice(first,1)
             illnessList.data[illnessIndex].push(value)
           }else {
             illnessList.data[illnessIndex].push(value)
           }
           console.log('未选中，则选中',illnessList.data[illnessIndex])
         }
       }
      }
    }

    const closeHealth = () => {
      // illnessList.data =  [ [0],[0],[0],[0],[0],[0],[0] ]
    }

    const tabChange = (val) => {
      if (val == 0) {
        // 0 基本信息
        isBasic.value = true
        isRelevancy.value = false
        details.value = false
      } else if (val == 1) {
        // 1 用药人信息
        isBasic.value = false
        details.value = false
        isRelevancy.value = true
        get_peoplelist()
      }
    }
    //查看用药人详情
    const viewMore = (index) => {
      // isBasic.value = false
      //
      // isRelevancy.value = false
      // details.value = true
      // peopleId.value = peopleList.data[index].id
      // proxy.$get("v3/member/front/memberMedicineUser/list", {
      //   memberID: memberId.value,
      //   ID: peopleId.value
      // }).then(res => {
      //   if (res.state == 200) {
      //     // let newphone = res.data.phone.replace(res.data.phone.substr(3, 4),"****")
      //     res.data.forEach((item) => {
      //       item.phone = item.phone.replace(item.phone.substr(3, 4), "****")
      //       item.idNumber = item.idNumber.replace(item.idNumber.substr(6, 6), "********")
      //     })
      //     duiList.data = res.data
      //   } else {
      //   }
      // })
    }
    //用药人
    const addPeople = () => {
      addForm.value = true
      let value = ''
      if(illnessList.data[0].length > 1 || illnessList.data[0][0] == 0){
        value += '无病史、'
      }
      if(illnessList.data[1].length > 1 || illnessList.data[1][0] == 0){
        value += '无药物过敏、'
      }
      if(illnessList.data[2].length > 1 || illnessList.data[2][0] == 0){
        value += '无食物/接触过敏史、'
      }
      if(illnessList.data[3].length > 1 || illnessList.data[3][0] == 0){
        value += '无家族病史、'
      }
      if(illnessList.data[4].length > 1 || illnessList.data[4][0] == 0){
        value += '无吸烟、'
      }
      if(illnessList.data[5].length > 1 || illnessList.data[5][0] == 0){
        value += '无饮酒、'
      }
      if(illnessList.data[6].length > 1 || illnessList.data[6][0] == 0){
        value += '无妊娠哺乳'
      }
      form.data.isHealth = value
    }

    // 协议弹出
    const agreement = () => {
      agreementForm.value = true
    }

    // 是否同意协议
    const checkbox = () => {
      checkboxv.value = !checkboxv.value
      ifcheckboxv.value = !checkboxv.value
    }

    //用药人返回
    const peopleBack = () => {
      isBasic.value = false
      isRelevancy.value = true
      details.value = false
    }
    //关闭弹窗
    const close_dialog = () => {
      ifcheckboxv.value = false
      checkboxv.value = false // 控制checked
      addForm.value = false // 关闭表单
      illnessList.data =  [ [0],[0],[0],[0],[0],[0],[0] ]
      addForms.value.resetFields() // 表单验证重置
      // location.reload();
    }
    //获取用药人列表
    const get_peoplelist = () => {
      proxy.$get("v3/member/front/memberMedicineUser/list").then(res => {
        if (res.state == 200) {
          if( res.data.length > 0) {
            isHavePeople.value = true
          }else {
            isHavePeople.value = false
          }
          res.data.forEach((item) => {
            item.phone = item.phone.slice(0,3) + '****' + item.phone.slice(7,11)
            // item.idNumber = item.idNumber.slice(0,6)+ '********' + item.idNumber.slice(14,18)
                // item.idNumber = item.idNumber.replace(item.idNumber.substr(6, 6), "********")
            // let org_gender = item.idNumber.substring(16, 17);
            // let peopleSex = ''
            // peopleSex = org_gender % 2 == 1 ? '男' : '女';
            // if (peopleSex == '男') {
            //   item.isPregnancy[0] = 'none'
            //   console.log('peopleSex',peopleSex)
            //   console.log('item.isPregnancy[0]',item.isPregnancy[0])
            // }
          })
          peopleList.data = res.data
        }
      })
    }
    // 时间转化
    const formatTen = (num) => {
      return num > 9 ? (num + "") : ("0" + num);
    }
    const formatDate = (date) => {
      var date = new Date(date)
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();
      return year + "-" + formatTen(month) + "-" + formatTen(day) + " " + formatTen(hour) + ":" + formatTen(minute) + ":" + formatTen(second);
    }
    //用药人确定
    const addConfirm = () => {
      let time = new Date()
      let date = formatDate(time)
      console.log('illnessList.data',illnessList.data)
      addForms.value.validate(valid => {
        ifcheckboxv.value = !checkboxv.value
        if (valid) {
          if (checkboxv.value) {
            var param = {
              isAdd: 1,
              idNumber: form.data.id.trim(),
              name: form.data.name.trim(),
              phone: form.data.phone.trim(),
              relation: form.data.region,
              creatBy: memberName.value,
              createTime: date,
              medicalHistory: illnessList.data[0].toString(),
              drugAllergy: illnessList.data[1].toString(),
              foodTouchAllergy: illnessList.data[2].toString(),
              geneticDisorders: illnessList.data[3].toString(),
              isWine: illnessList.data[4].toString(),
              isSmoking: illnessList.data[5].toString(),
              isPregnancy: illnessList.data[6].toString(),
            };
            var url = "v3/member/front/memberMedicineUser/add";
            proxy.$post(url, param).then(res => {
              if (res.state == 200) {
                // btnLoading.value = false
                // close_dialog();
                // emit("refreshAddress", param, res.data);
                ElMessage.success(res.msg);
                addForm.value = false
                get_peoplelist()
              } else {
              }
            })
          }
        } else {
          return false;
        }
      });
      // });
    }
    // 身份证号表单验证,
    const valiIdNumber = (rule, value, callback) => {
      setTimeout(() => {
        if (value === '') {
          callback(new Error('请输入身份证号'));
        } else if (!ids.value) {
          callback(new Error('请输入正确的身份证号'));
        }else if (isId.value) {
          callback(new Error('用药人身份证号重复'));
        }  else {
          callback();
        }
        let data = value.toUpperCase() //转换成大写
        ids.value = IdentityCodeValid( data )// 验证身份证号
      },100)
    };

    // 与用药人关系表单验证,
    const valiRelation = (rule, value, callback) => {
      setTimeout(() => {
        if (!value) {
          callback(new Error('请选择与用药人关系'));
        } else if (isRelation.value) {
          callback(new Error('用药人本人关系已存在'));
        }  else {
          callback();
        }
      },100)
    };

    // 新增校验
    const rules = {
      name: [
        {required: true, message: "请输用药人姓名", trigger: "change"},
        {min: 2, max: 10, message: "请输入2~10个字符", trigger: "change"}
      ],
      id: [
        { validator: valiIdNumber, required: true, trigger: "change"},
        // {
        //   pattern: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{4}$/,
        //   message: "请输入正确的身份证号",
        //   trigger: "change"
        // }
      ],
      phone: [
        {required: true, message: "请输入手机号", trigger: "change"},
        {
          pattern: /^((\+?86)|(\(\+86\)))?(1[3-9]\d{9}$)|(\d{4}-)\d{6,8}$/,
          message: "请输入正确的电话号码",
          trigger: "change"
        }
      ],
      phoneCode: [
        {required: false,  trigger: "change"},
        {
          pattern: /^\d{6}$/,
          message: "请输入6位数字验证码",
          trigger: "change"
        }
      ],
      region: [
        {required: true, validator: valiRelation, trigger: "change"},
        // {min: 2, max: 25, message: "请选择与用药人关系", trigger: "change"}
      ],
      isHealth: [
        {required: false, trigger: "change"},
      ],
    }; //输入校验
    const addForms = ref(null);
    //是否同意用户注册协议
    const agree = () => {
      agreeFlag.value = !agreeFlag.value;
    }
    const changepic = (e) => { //上传头像
      let newsrc = getObjectURL(e.target.files[0]);
      let data = new FormData()
      data.append('file', e.target.files[0])
      data.append('source', 'headImg')
      let config = {
        headers: {'Content-Type': 'multipart/form-data'}
      }
      let url = apiUrl + "/v3/oss/common/upload";
      if (e.target.files[0].size < Math.pow(1024, 2) * 4) {
        tempSrc.value = newsrc
        axios.post(url, data, config).then(res => {
          if (res.status === 200) {
            ElMessage.success('上传图片成功')
            tempSrc.value = res.data.data.url
            avatar.value = res.data.data.path
          } else {
            ElMessage.warning('上传图片失败')
          }
        })
      } else {
        ElMessage.warning('图像应小于4MB')
        tempSrc.value = avatar.value
      }
    }
    const getObjectURL = (file) => { //createObjectURL在前端显示更换后的头像
      var url = null;
      if (window.createObjectURL != undefined) { // basic
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) { // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) { // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      }
      return url;
    }

    const getInitData = () => {//初始化数据
      proxy.$get('/v3/member/front/member/memberInfo').then(res => {
        if (res.state == 200) {
          memberName.value = res.data.memberName
          memberId.value = res.data.memberId
          sex.value = res.data.gender
          memberNameInput.value = res.data.memberName
          memberNickNameInput.value = res.data.memberNickName
          tempSrc.value = res.data.memberAvatar
          memberTrueName.value = res.data.memberTrueName
          year.value = res.data.memberBirthday.split('-')[0]
          month.value = parseInt(res.data.memberBirthday.split('-')[1])
          day.value = parseInt(res.data.memberBirthday.split('-')[2])
        }
      })
    }

    const memberInfoSave = () => {//保存
      // let params = new FormData()

      if (!memberNickNameInput.value.trim()) {
        ElMessage.warning('请输入昵称')
        return
      }

      if (!avatar.value) {
        avatar.value = tempSrc.value
      }


      let birthday = [year.value, month.value, day.value].map(item => item.toString().length < 2 ? '0' + item : item).join('-')
      let params = {
        gender: sex.value,
        memberAvatar: avatar.value,
        memberBirthday: birthday,
        memberName: memberNameInput.value,
        memberNickName: memberNickNameInput.value.trim()

      }
      if (memberTrueName.value) {
        params.memberTrueName = memberTrueName.value.trim()
        let reg = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im
        if (reg.test(memberTrueName.value)) {
          ElMessage.warning('真实姓名包含特殊字符，请重新输入')
          return
        }
      }

      proxy.$post('/v3/member/front/member/updateInfo', params).then(res => {
        if (res.state == 200) {
          ElMessage.success(res.msg)
          getInitData()
        } else {
          ElMessage.warning(res.msg)
        }
      })
    }

    watch(year, () => {//监听设置的年，如果是现在的年份，则月和天不会超过今天的年月日
      let time = new Date()
      if (year.value == time.getFullYear()) {
        dayArr.value = dayArr.value.filter(item => item <= time.getDate())
        monthArr.value = monthArr.value.filter(item => item <= time.getMonth() + 1)
      } else {
        dayArr.value = Array.from({length: d}, (v, k) => k).map(item => item + 1)//生成当月天数数组
        monthArr.value = Array.from({length: 12}, (v, k) => k).map(item => 12 - item)//生成当月的月数
      }
    })

    watch(month, () => {
      let time = new Date()
      d = new Date(year.value, month.value, 0).getDate();
      dayArr.value = Array.from({length: d}, (v, k) => k).map(item => item + 1)//生成当月天数数组
      if (year.value == time.getFullYear() && month.value == time.getMonth() + 1) {
        dayArr.value = dayArr.value.filter(item => item <= time.getDate())
      } else {
        dayArr.value = Array.from({length: d}, (v, k) => k).map(item => item + 1)//生成当月天数数组
      }
    })


    onMounted(() => {
      getInitData()
      tabChange(0)
    })
    return {
      L,
      memberNameInput,
      memberNickNameInput,
      sex,
      yearArr,
      year,
      monthArr,
      month,
      dayArr,
      day,
      avatar,
      memberInfoSave,
      changepic,
      tempSrc,
      memberTrueName,
      tabChange,
      isBasic,
      isRelevancy,
      viewMore,
      addPeople,
      checkbox,
      addForm,
      checkboxv,
      ifcheckboxv,
      form,
      formLabelWidth,
      codelWidth,
      details,
      peopleBack,
      rules,
      addConfirm,
      addForms,
      close_dialog,
      agreeFlag,
      errorMsg,
      agree,
      memberId,
      memberName,
      formatDate,
      formatTen,
      peopleList,
      get_peoplelist,
      peopleId,
      duiList,
      agreement,
      agreementForm,
      healthStateDialog,
      illnessList,
      healthState,
      handleIllnessClick,
      handleBtnClass,
      illConfirm,
      viewHealthState,
      viewState,
      illList,
      placeholder,
      closeHealth,
      getId,
      disables,
      uerSex,
      ids,
      valiIdNumber,
      valiRelation,
      selRegion,
      isRelation,
      isId,
      isHavePeople,
    }
  }
}
</script>
<style lang="scss">
@import '../../style/member/info.scss';
@import "../../style/base.scss";

.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 302px;
}

.illness_box {
  margin-bottom: 15px;
}

.illness_title {
  text-align: right;
  display: inline-block;
  margin-right: 20px;
  padding-top: 10px;
  width: 15%;
}

.illness {
  display: inline-block;
  width: 80%;
}

.illness_btn {
  margin: 0 20px 15px 0;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  background-color: #fff;
  padding: 7px 17px;
}

.illness_btn_selected {
  background-color: $colorMain;
  color: #FFFFFF;
}

.el-radio-button__inner,
.el-radio-group {
  display: flex;
}

.title {
  position: relative;
  width: 100%;
  height: 40px;
  padding-top: 7px;
  background-color: #F2F2F2FF;
}


.title li {
  display: inline-block;
  border: 1px solid #CCCCCCFF;
  background-color: #fff;
  text-align: center;
  width: 50%;
  height: 40px;
  line-height: 40px;
}

.title div {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  width: 100px;
  background-color: #fff;
  height: 30px;
  line-height: 30px;
  border: 1px solid #CCCCCCFF;
  color: #999999;
}

.basic_title {
  border-right: none !important;
}

.sel_title {
  color:  $colorMain !important;
}

.add_people {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #fff;
  color:  $colorMain;
  font-size: 13px;
}

.rel_people {
  position: relative;
  text-align: center;
  width: 90%;
  height: 130px;
  margin: 15px auto;
  border: 1px solid #F2F2F2FF;
}

.is_health {
  padding-left: 30px;
  text-align: left;
  cursor: pointer;
  width: 100%;
  position: absolute;
  height: 35px;
  line-height: 35px;
  background-color: #D7D7D7FF;
  bottom: 0;
}

.rel_people_top {
  position: relative;
}

.people_box {
  position: absolute;
  left: 130px;
}

.avatar_box{
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 10px;
}

.view_more {
  display: inline-block;
  height: 79px;
  line-height: 79px;
  font-size: 13px;
  color: #169BD5;
  margin-right: 20px;
}

.view_more:hover {
  cursor: pointer;
}

.title_box {
  display: flex;
  justify-content: space-between;
  padding-left: 15px
}

.people_mes {
  max-width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 23px 0 15px 20px;
}

.people_name {
  max-width: 210px;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow:ellipsis;
  display: inline-block;
  font-size: 18px;
  font-weight: bold
}

.number_box {
  text-align: left;
  margin-top: 10px;
  padding-left: 15px;
}

.idCard {
  position: absolute;
  left: 45%;
  top: 53px;
  font-size: 14px;
}

.number {
  display: inline-block;
  color: #AAAAAA;
  font-size: 14px;
  margin-left: 0;
}

.relation {
  font-size: 12px;
  display: inline-block;
  border: 1px solid #333333;
  border-radius: 3px;
  width: 42px;
  height: 21px;
  line-height: 21px;
}

.relevancy_mes {
  text-align: right;
}

.rel_people_box {
  overflow: auto;
  height: 564px;
}

.people_detial {
  height: 589px;
}

.detail_title {
  height: 37px;
  font-size: 13px;
  line-height: 37px;
  background-color: rgba(255, 0, 0, 0.145098039215686);
}

.detail_title:hover {
  cursor: pointer;
}

.personal_detial {
  display: flex;
  justify-content: center;
  padding: 10px 0 20px;
  margin: 0 25px;
  border-bottom: 1px solid #cccccc;
}

.personal_mes {
  width: 480px;
  height: 60px;
  margin-left: 35px;
}

.personal_public {
  font-size: 16px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin: 16px 0 5px 0;
}

.order_table {
  overflow: auto;
  border-collapse: collapse;
  padding: 0;
  text-align: center;
  margin: 30px 25px 0;
  width: 90%;
  height: 350px;
  font-size: 16px;
}

.table_title {
  box-sizing: border-box;
  background-color: #f2f2f2;
  border: 1px solid #CCCCCCFF;
  padding: 0;
  margin: 20px auto 0;
}

.table_title .table_title_pre:nth-child(1) {
  margin: 0 180px 0 0;
}

.table_title .table_title_pre:nth-child(2),
.table_title .table_title_pre:nth-child(3),
.table_title .table_title_pre:nth-child(4) {
  margin: 0 120px 0 0;
}

//.table_title .table_title_pre:nth-child(5) {
//  margin: 0;
//}

.table_title_pre {
  display: inline-block;
}

.orde_mes {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #f2f2f2;
  border: 1px solid #CCCCCCFF;
  padding: 5px 20px;
  margin: 20px auto 0;
}

.goods_con {
  padding: 20px 15px;
  border: 1px solid #EEEEEE;
}

.goods_list {
  display: flex;
  justify-content: space-around;
}

.goods_list_left {
  width: 450px;
  display: flex;
  justify-content: space-between;
}

.goods_list_left .numbers div {
  margin: 13px 0 0 15px;
}

.goods_pic {
  display: flex;
  font-size: 14px;
}

.goods_list_middle {
  width: 100px;
  height: 75px;
  line-height: 74px;
  border-left: 1px solid #9f9f9f;
  border-right: 1px solid #9f9f9f;
}

.goods_list_right {
  width: 50px;
  height: 75px;
  line-height: 74px;
}

.goods_number {
  line-height: 74px;
}

.bind_code {
  position: relative;
  .bind_code_gain {
    position: absolute;
    top: calc(50% - 20px);
    right: 98px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: $colorMain;
    line-height: 18px;
    cursor: pointer;
    padding: 5px 10px;
    border-left: 1px solid #d8d8d8;
  }

  .small {
    display: inline-block;
    color: red;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

  .noPeople {
    text-align: center;
    font-size: 22px;
  }

  .add_btn {
    position: absolute;
    right: 10px;
  }

  .colors {
    color:  $colorMain;
  }

  .colors:hover {
    text-decoration: underline;
  }

  .el-btn, .el-button:hover {
    border: none !important;
    color: #FFFFFF !important;
    background-color: $colorMain !important;
  }

  .is-checked {
    border-color:  $colorMain;
  }

  .el-checkbox__inner {
    border-color:  $colorMain !important;
  }

  .is-checked .el-checkbox__inner {
    background-color: $colorMain !important;
  }

  .is-checked .el-checkbox__label {
    color: #606266 !important;
  }
</style>